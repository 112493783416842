/**
 * All Value
 */
export const AllValue = 'All';

/**
 * All Value Parameter
 */
export const AllValueParameter = '$__all';

/**
 * No Value Parameter
 */
export const NoValueParameter = '$__empty';
